import React, {useEffect, useState} from 'react'
import '../css/HeaderRed.css'
// import '../css/Navbar.css'
import devlog from '../utilities/Devlog.js';
import axios from 'axios'


export default function Navbar({ setIsLoggedIn }) {

    const [user, setUser] = useState();

    useEffect(() => {
        const fetchUser = async () => {
        const token = localStorage.getItem('token')
        if (!token) {
            devlog("no token")
            return;
        }
        try {
            // query user from endpoint
            devlog("querying user...")
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/getUser`,
            { token: token },
            { withCredentials: true })
            
            // devlog(response)
            if (response.data.success) {
                devlog("successfully fetched user")
                setUser(response.data.user)
                setIsLoggedIn(true)

            } else {
            devlog("Failed to fetch user")
            }

        } catch (e) {
            console.error("App.js:", e)
        }
        }
    fetchUser();
    }, [])


    /* logout function */
    function logout() {
        localStorage.removeItem('token');
        setUser(null)
        setIsLoggedIn(false)
        // devlog(token)
    }

    function toggleMenu() {
        devlog("toggle menu")
    }

    useEffect(() => {
        if (user) {
            setIsLoggedIn(true)
        }
    }, [user])

    return (
        <div className="navbar-overlay">
            <div className="navbar-items">
                <p className="navbar-logo">AOA</p>
                <div className="navbar-links">
                    { user && <p className="navbar-welcome">Welcome, { user.username }  </p>}
                    {/* <p>How it works</p> */}
                    { user && <a href="#" onClick={ logout }> Logout </a> }
                    { !user && <a className="navbar--welcome" href={`${process.env.REACT_APP_CLIENT_URL}/login`}> Login/Signup</a> }
                </div>
                <div className="hamburger-menu" onClick={toggleMenu}>
                    &#9776; {/* This is the hamburger icon */}
                </div>
            </div>
            </div>
    )

}